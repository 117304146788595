
.navbar {
  padding: 0.5rem 1rem;
}


.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, 
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: $green;
}


.btn {
  text-transform: none;
  cursor: pointer;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, 
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: $blue;
}

.intercom-launcher-frame {
  display: none !important;
}