$explorer: #2176ff;
$place: #8dc500;
$observation: #00B5B8;
$lead: #9336fd;
$project: #ff8800;
$task: #ff7370;
$admin: #f050ae;


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}


.badge-explorers
  {
    color: #fff;
    background-color: $explorer;
  }
  .text-explorers {
    color: $explorer;
  }

  .badge-places
  {
    color: #fff;
    background-color: $place;
  }
  .text-places {
    color: $place;
  }

  .badge-observations {
    color: #fff;
    background-color: $observation;
  }

  .text-observations {
    color: $observation;
  }

  .badge-leads
  {
    color: #fff;
    background-color: $lead;
  }
  .text-leads {
    color: $lead;
  }

  .badge-projects
  {
    color: #fff;
    background-color: $project;
  }
  .text-projects {
    color: $project;
  }

  .badge-tasks
  {
    color: #fff;
    background-color: $task;
  }
  .text-tasks {
    color: $task;
  }

  .badge-admins
  {
    color: #fff;
    background-color: $admin;
  }
  .text-admins
  {
    color: $admin;
  }





  .text-lead {
    @extend .text-info
  }

  .metadata-tags-container{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: rgba(0,0,0,0.6);
  }

  .metadata-tags-container:hover{
    opacity: 1;
  }

  .google-map-edit {
      position: relative;
  }

  .google-map-edit:after {
      width: 22px;
      height: 32px;
      display: block;
      content: ' ';
      position: absolute;
      top: 50%; left: 50%;
      margin: -32px 0 0 -11px;
      background: url('https://maps.gstatic.com/mapfiles/markers2/arrow.png');
      pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
  }

  .google-match-card {
    &.hovered {
      @extend .border-primary;
      transform: scale(1.05);
      transform-origin: left center;
    }

    &.active {
      @extend .bg-primary;
      @extend .text-white;
      a {
        @extend .text-white;
      }

      &.hovered {
        @extend .border-light;
      }
    }
    transform: scale(1.0);
    transform-origin: left center;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  @for $i from 0 through 50
  {
    .scale-#{$i}
    {
      color: scale-color(mix($yellow, $red, $i*2), $saturation: -20%)
    }

    .scale-bg-#{$i}
    {
      background-color: scale-color(mix($yellow, $red, $i*2), $saturation: -20%)
    }
  }

  @for $i from 51 through 100
  {
    .scale-#{$i}
    {
      color: scale-color(mix($green, $yellow, ($i - 51)*2), $saturation: -20%)
    }

    .scale-bg-#{$i}
    {
      background-color: scale-color(mix($green, $yellow, ($i - 51)*2), $saturation: -20%)
    }
  }
